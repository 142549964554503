<template>
  <itemCard type="primary" :data="computedData" :action="action" :actionText="actionText" />
</template>

<script>
export default {
  components: {
    itemCard: () => import("@/modules/base/components/itemCard/itemCard.vue"),
  },
  props: {
    data: Object,
  },
  computed: {
    cooperateProviderId() {
      if(!this.data) return ''
      return this.data.providerId
    },
    layoutProviderId() {
      return this.$route.params.providerId
    },
    computedData() {
      return [
        {
          text: "供應商",
          value: this.data.name,
        },
        {
          text: "合作狀態",
          value: this.$t(`provider.status.detail.${this.data.status}`),
          class: this.statusColorClass,
        },
      ];
    },
    statusColorClass() {
      if (
        this.data.status ==
        this.$providerStoreCooperation.STORE_RELATIONSHIP_STATUS_REJECT
      )
        return "red--text";
      return "primary--text";
    },
    actionText() {
      return "查看";
    },
  },
  methods: {
    action() {
      this.$router.push({
        name: "store-cooperation-info",
        params: { 
          providerId: this.layoutProviderId,
          cooperateProviderId: this.cooperateProviderId
        },
      });
    },
  },
};
</script>